<template>
  <section
    style="overflow: scroll"
    v-loading="loading"
    :style="getTableStyles"
    class="ml-1 mt-3"
    v-on:mouseleave="hideEdit"
  >
    <div class="top-bar-container">
      <div class="item">
        <p class="f-weight-500 blue mb-1">
          {{ repeatableTemplateTitle }}
        </p>
      </div>
      <div class="item">
        <el-switch
          v-model="isOn"
          inactive-color="#ff4949"
          active-color="#13ce66"
          inactive-text="Table"
          active-text="Kanban"
        >
        </el-switch>
      </div>
      <div class="item">
        <el-button
          class="btn edit-btn float-right mb-1"
          size="mini"
          type="primary"
          v-if="!isView && !isOn"
          @click="addRowToDataTable"
          >Add Data</el-button
        >
        <el-button
          class="btn edit-btn float-right"
          size="mini"
          type="primary"
          v-if="!isView && isOn"
          @click="kanbanpop"
          >Add Data</el-button
        >
      </div>
    </div>

    <div v-loading="loading">
      <el-table
        :data="entitiesData"
        class="sidemenu"
        style="width: 98%"
        border
        stripe
        v-if="
          this.tableHeaders && this.tableHeaders.length && this.isOn == false
        "
      >
        <el-table-column
          v-for="column in tableHeaders"
          :key="column.label"
          fixed
          style="word-break: break-word"
          :resizable="true"
          :prop="column.key"
          :label="column.label"
          :min-width="column.inputType == 'ENTITY' ? 250 : 150"
        >
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.indexKey && selectedRowIndex == scope.row.indexKey
              "
              class="field-style"
            >
              <div
                v-if="
                  column.inputType == 'DATE' &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <el-date-picker
                  placeholder="Select date"
                  class="smaller-than"
                  size="large"
                  style="width:100% !important"
                  v-model="selectedRow[column.key]"
                  :disabled="checkIsDisabled(column, scope.row.indexKey)"
                  :format="
                    getDefaultDateFormat &&
                    getDefaultDateFormat.includes('Do') == false
                      ? getDefaultDateFormat
                          .replace('YYYY', 'yyyy')
                          .replace('DD', 'dd')
                      : 'MM-dd-yyyy'
                  "
                  @change="rulesEventEmitter(scope.row.indexKey)"
                >
                  >
                </el-date-picker>
              </div>
              <div
                v-else-if="
                  column.inputType === 'TIME' &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <TimeExecute
                  :data="column"
                  :form="selectedRow"
                  :is-view="false"
                  :checkIsDisabled="checkIsDisabled(column)"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></TimeExecute>
              </div>
              <div
                v-else-if="
                  column.inputType === 'CALENDER' &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <calender-execute
                  :data="column"
                  :form="selectedRow"
                  :is-view="false"
                  :checkIsDisabled="checkIsDisabled(column)"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></calender-execute>
              </div>
              <div
                v-else-if="
                  column.inputType === 'ICON' &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <icon-execute
                  :data="column"
                  :form="selectedRow"
                  :is-view="false"
                  :checkIsDisabled="checkIsDisabled(column)"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></icon-execute>
              </div>
              <div
                v-else-if="
                  column.inputType === 'RANDOM_TEXT' &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <random-text-execute
                  :data="column"
                  :form="selectedRow"
                  :is-view="false"
                  :checkIsDisabled="checkIsDisabled(column)"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></random-text-execute>
              </div>
              <div
                v-else-if="
                  column.inputType === 'TIME_RANGE' &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <TimeRangeExecute
                  :data="column"
                  :form="selectedRow"
                  :is-view="false"
                  :checkIsDisabled="checkIsDisabled(column)"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></TimeRangeExecute>
              </div>
              <div
                v-else-if="
                  column.inputType === 'DATE_TIME' &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <el-date-picker
                  class="smaller-than"
                  size="mini"
                  prefix-icon="el-icon-date"
                  v-model="selectedRow[column.key]"
                  type="datetime"
                  placeholder="Select Date Time"
                  :format="'MM-dd-yyyy hh:mm:ss'"
                  :disabled="checkIsDisabled(column, scope.row.indexKey)"
                  @change="rulesEventEmitter(scope.row.indexKey)"
                >
                </el-date-picker>
              </div>
              <div
                v-else-if="
                  column.inputType === 'DATE_TIME_RANGE' &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <DateTimeRangeExecute
                  :data="column"
                  :form="selectedRow"
                  :fromRepeatable="true"
                  :is-view="false"
                  :fieldsData="tableHeaders"
                  :checkIsDisabled="checkIsDisabled(column, scope.row.indexKey)"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></DateTimeRangeExecute>
              </div>
              <div
                v-else-if="
                  column.inputType === 'DATE_RANGE' &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <DateRangeExecute
                  :data="column"
                  :form="selectedRow"
                  :fromRepeatable="true"
                  :is-view="false"
                  :fieldsData="tableHeaders"
                  :checkIsDisabled="checkIsDisabled(column, scope.row.indexKey)"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></DateRangeExecute>
              </div>

              <div
                v-else-if="
                  column.inputType == 'MULTI_SELECT' &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <el-select
                  collapse-tags 
                  placeholder="Please select..."
                  :clearable="true"
                  :filterable="true"
                  :multiple="column.inputType == 'MULTI_SELECT' ? true : false"
                  :allow-create="true"
                  :default-first-option="true"
                  v-model="selectedRow[column.key]"
                  :disabled="
                    (column.inputType == 'MULTI_SELECT' &&
                      column.options &&
                      !column.options.length) ||
                    checkIsDisabled(column, scope.row.indexKey)
                  "
                  @change="rulesEventEmitter(scope.row.indexKey)"
                >
                  <el-option
                    v-for="op in column.options"
                    :key="op"
                    :label="op"
                    :value="op"
                  ></el-option>
                </el-select>
              </div>
              <div
                v-else-if="
                  column.inputType == 'CHECKBOX' &&
                  !column.group_key &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <el-checkbox v-model="selectedRow[column.key]"> </el-checkbox>
              </div>
              <!-- <div v-else-if="column.inputType === 'CHECKBOX_GROUP' && column.group_key">
                <CheckBoxGroupExecute
                  :data="column"
                  :form="selectedRow"
                  :fromRepeatable="true"
                  :is-view="false"
                  :fieldsData="tableHeaders"
                ></CheckBoxGroupExecute>
              </div> -->
              <div
                v-else-if="
                  column.inputType == 'CURRENCY' &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <CurrencyExecute
                  :data="column"
                  :form="selectedRow"
                  :fromRepeatable="true"
                  :is-view="false"
                  :fieldsData="tableHeaders"
                  :checkIsDisabled="checkIsDisabled(column, scope.row.indexKey)"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></CurrencyExecute>
              </div>
              <div v-else-if="column.inputType == 'PAYMENT_BUTTON'">
                <PaymentExecute
                  :data="column"
                  :form="selectedRow"
                  :fromRepeatable="true"
                  :is-view="false"
                  :fieldsData="tableHeaders"
                ></PaymentExecute>
              </div>
              <div v-else-if="column.inputType == 'YES_OR_NO' &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                ">
                <YesOrNoExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :is-view="false"
                :fieldsData="tableHeaders"
              ></YesOrNoExecute>
             </div>
            <div v-if="column.inputType === 'SIGNATURE' &&
                scope.row.indexKey &&
                !checkhideFields(column, scope.row.indexKey)
                ">
                <ESignatureExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :is-view="false"
                :fieldsData="tableHeaders">
                </ESignatureExecute>
            </div>
            <div v-if="column.input_type === 'PHONE_COUNTRY_CODE' &&
              scope.row.indexKey &&
              !checkhideFields(column, scope.row.indexKey)">
              <PhoneCountryCodeExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :is-view="false"
                :fieldsData="tableHeaders">
              </PhoneCountryCodeExecute>
            </div>
            <div v-else-if="(column.inputType == 'SINGLE_LINE_TEXT' ||
                    column.inputType === 'EMAIL' ||
                    column.inputType === 'PASSWORD' ||
                    column.inputType === 'MASKED' ||
                    column.inputType === 'HYPER_LINK') &&
               scope.row.indexKey &&
              !checkhideFields(column, scope.row.indexKey)">
                <SingleLineTextExecute
                  :data="column"
                  :form="selectedRow"
                  :fromRepeatable="true"
                  :is-view="false"
                  :fieldsData="tableHeaders"
                  :checkIsDisabled="checkIsDisabled(column, scope.row.indexKey)"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></SingleLineTextExecute>
              </div>
              <div
                v-else-if="
                  (column.inputType == 'NUMBER' ||
                   column.inputType == 'ZIP_CODE' ||
                    column.inputType == 'PHONE' ||
                    column.inputType == 'FAX') &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <NumberExecute
                  :data="column"
                  :form="selectedRow"
                  :fromRepeatable="true"
                  :is-view="false"
                  :fieldsData="tableHeaders"
                  :checkIsDisabled="checkIsDisabled(column, scope.row.indexKey)"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></NumberExecute>
              </div>

              <div
                v-else-if="
                  column.inputType == 'MULTI_LINE_TEXT' &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <MultiLineTextExecute
                  :data="column"
                  :form="selectedRow"
                  :fromRepeatable="true"
                  :is-view="false"
                  :fieldsData="tableHeaders"
                  :checkIsDisabled="checkIsDisabled(column, scope.row.indexKey)"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></MultiLineTextExecute>
              </div>

              <div
                v-else-if="
                  column.inputType === 'SELECT' &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <SelectExecute
                  :data="column"
                  :form="selectedRow"
                  :fromRepeatable="true"
                  :is-view="false"
                  :fieldsData="tableHeaders"
                  :checkIsDisabled="checkIsDisabled(column, scope.row.indexKey)"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></SelectExecute>
              </div>

              <div
                v-else-if="
                  column.inputType === 'LIST' &&
                  scope.row.indexKey &&
                  !checkhideFields(column)
                "
              >
                <ListExecute
                  :data="column"
                  :form="selectedRow"
                  :fromRepeatable="true"
                  :is-view="false"
                  :fieldsData="tableHeaders"
                ></ListExecute>
              </div>

              <div
                v-else-if="
                  column.inputType == 'FORMULA' &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <FormulaExecute
                  :data="column"
                  :form="selectedRow"
                  :fromRepeatable="true"
                  :is-view="false"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></FormulaExecute>
              </div>
              <div
                v-else-if="
                  column.inputType == 'ENTITY' &&
                  scope.row.indexKey &&
                  !checkhideFields(column)
                "
              >
                <EntityExecute
                  :data="column"
                  :form="selectedRow"
                  :is-view="false"
                  :fieldsData="tableHeaders"
                  :fromRepeatable="true"
                  @entityDataUpdated="emitDataToEntityVariables"
                  @clearEntityFields="emitUnsetEntityVariables"
                ></EntityExecute>
              </div>
              <div
                v-else-if="
                  column.inputType === 'CHECKBOX_GROUP' &&
                  scope.row.indexKey &&
                  !checkhideFields(column)
                "
              >
                <CheckBoxGroupExecute
                  :data="column"
                  :form="selectedRow"
                  :fromRepeatable="true"
                  :isView="false"
                  :fieldsData="tableHeaders"
                  :hasLabel="hasLabel"
                ></CheckBoxGroupExecute>
              </div>
              <div
                v-else-if="
                  column.inputType === 'STAR_RATING' &&
                  scope.row.indexKey &&
                  !checkhideFields(column)
                "
              >
                <StarRatingExecute
                  :data="column"
                  :form="selectedRow"
                  :fromRepeatable="true"
                  :isView="false"
                  :fieldsData="tableHeaders"
                  :hasLabel="!hasLabel"
                ></StarRatingExecute>
              </div>
              <div  v-else-if="
                  column.inputType === 'CONCATENATE' &&
                  scope.row.indexKey &&
                  !checkhideFields(column)
                ">
                <ConcatenateExecute
                    :data="column"
                    :form="selectedRow"
                    :hasLabel="hasLabel"
                    :fromRepeatable="true"
                    :isView="false"
                    :fieldsData="tableHeaders"
                  >
                </ConcatenateExecute>
              </div>
              <div v-else-if="column.inputType === 'RADIO_BUTTON_GROUP' && !checkhideFields(column)">
                <RadioButtonGroupExecute
                  :data="column"
                  :form="selectedRow"
                  :fromRepeatable="true"
                  :isView="false"
                  :fieldsData="tableHeaders"
                  :hasLabel="hasLabel"
                  
                ></RadioButtonGroupExecute>
              </div>

              <div
                v-else-if="
                  column.inputType == 'FILE' &&
                  scope.row.indexKey &&
                  !checkhideFields(column)
                "
            >
                <FileExecute
                    :data="column"
                    :form="selectedRow"
                    :is-view="false"
                    :fromRepeatable="true"
                    :fieldsData="tableHeaders"
                    :hasLabel="!hasLabel"
                    :isFromRepeatable="true"
                    :filesInfo="filesInfo"
                ></FileExecute>
              </div>
              <div
                v-else-if="column.inputType == 'CHECKBOX' && column.group_key"
              >
                <CheckBoxExecute
                  :data="column"
                  :form="selectedRow"
                  :isView="false"
                  :fromRepeatable="true"
                  :fieldsData="tableHeaders"
                  :hasLabel="hasLabel"
                  :singleCompanyTemplateCheckBoxArray="
                    singleCompanyTemplateCheckBoxArray
                  "
                ></CheckBoxExecute>
              </div>
              <div v-else-if="column.inputType == 'WEEKDAYS'">
                <WeekDaysExecute
                  :data="column"
                  :form="selectedRow"
                  :hasLabel="hasLabel"
                  :isView="false"
                  :fromRepeatable="true"
                >
                </WeekDaysExecute>
              </div>
            </div>
            <div
              v-else-if="
                scope.row[column.key] ||
                scope.row[column.key] == true ||
                scope.row[column.key] == false
              "
            >
              <p v-if="column.inputType == 'DATE'">
                {{ scope.row[column.key] | moment("MM-DD-YYYY") }}
              </p>
              <p v-else-if="column.inputType == 'DATE_TIME'">
                {{ scope.row[column.key] | moment("MM-DD-YYYY HH:mm:ss") }}
              </p>
              <p v-else-if="column.inputType == 'DATE_TIME_RANGE'">
                {{
                  scope.row[`${column.key}_from`]
                    | moment("MM-DD-YYYY HH:mm:ss")
                }}
                (From ~ To)
                {{
                  scope.row[`${column.key}_to`] | moment("MM-DD-YYYY HH:mm:ss")
                }}
              </p>
              <p v-else-if="column.inputType == 'DATE_RANGE'">
                {{
                  scope.row[`${column.key}_from`]
                    | moment("MM-DD-YYYY")
                }}
                (From ~ To)
                {{
                  scope.row[`${column.key}_to`] | moment("MM-DD-YYYY")
                }}
              </p>
              <!-- <p v-else-if="column.inputType == 'MULTI_SELECT'">
                {{ scope.row[column.key].join(", ") }}
              </p>
              <p v-else-if="column.inputType == 'MULTI_SELECT'">
                {{ scope.row[column.key].join(", ") }}
              </p> -->
              <div
                v-else-if="
                  column.inputType == 'CURRENCY' &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <CurrencyExecute
                  :data="column"
                  :form="scope.row"
                  :fromRepeatable="true"
                  :is-view="true"
                  :checkIsDisabled="checkIsDisabled(column, scope.row.indexKey)"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></CurrencyExecute>
              </div>
              <div v-else-if="column.inputType == 'PAYMENT_BUTTON'">
                <PaymentExecute
                  :data="column"
                  :form="scope.row"
                  :fromRepeatable="true"
                  :is-view="true"
                ></PaymentExecute>
              </div>
              <div v-else-if="column.inputType == 'PHONE_COUNTRY_CODE' &&
                scope.row.indexKey &&
                !checkhideFields(column, scope.row.indexKey)">
                <PhoneCountryCodeExecute
                  :data="column"
                  :form="scope.row"
                  :fromRepeatable="true"
                  :is-view="true"
                  :fieldsData="tableHeaders"
                  :checkIsDisabled="checkIsDisabled(column, scope.row.indexKey)"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                >
                </PhoneCountryCodeExecute>
              </div>
              <div v-else-if="(column.inputType === 'SINGLE_LINE_TEXT' ||
                    column.inputType === 'EMAIL' ||
                    column.inputType === 'PASSWORD' ||
                    column.inputType === 'MASKED' ||
                    column.inputType === 'HYPER_LINK') &&
                 scope.row.indexKey &&
                 !checkhideFields(column, scope.row.indexKey)">
                <SingleLineTextExecute
                  :data="column"
                  :form="scope.row"
                  :fromRepeatable="true"
                  :is-view="true"
                  :fieldsData="tableHeaders"
                  :checkIsDisabled="checkIsDisabled(column, scope.row.indexKey)"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></SingleLineTextExecute>
              </div>

              <div
                v-else-if="
                  column.inputType == 'MULTI_SELECT' &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <MultiSelectExecute
                  :data="column"
                  :form="scope.row"
                  :fromRepeatable="true"
                  :is-view="true"
                  :fieldsData="tableHeaders"
                  :checkIsDisabled="checkIsDisabled(column, scope.row.indexKey)"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></MultiSelectExecute>
              </div>

              <div
                v-else-if="
                  (column.inputType == 'NUMBER' ||
                    column.inputType == 'PHONE' ||
                    column.inputType == 'ZIP_CODE' ||
                    column.inputType == 'FAX') &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <NumberExecute
                  :data="column"
                  :form="scope.row"
                  :fromRepeatable="true"
                  :is-view="true"
                  :fieldsData="tableHeaders"
                  :checkIsDisabled="checkIsDisabled(column, scope.row.indexKey)"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></NumberExecute>
              </div>

              <div
                v-else-if="
                  column.inputType == 'MULTI_LINE_TEXT' &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <MultiLineTextExecute
                  :data="column"
                  :form="scope.row"
                  :fromRepeatable="true"
                  :is-view="true"
                  :fieldsData="tableHeaders"
                  :checkIsDisabled="checkIsDisabled(column, scope.row.indexKey)"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></MultiLineTextExecute>
              </div>

              <div
                v-else-if="
                  column.inputType === 'SELECT' &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <SelectExecute
                  :data="column"
                  :form="scope.row"
                  :fromRepeatable="true"
                  :is-view="true"
                  :fieldsData="tableHeaders"
                  :checkIsDisabled="checkIsDisabled(column, scope.row.indexKey)"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></SelectExecute>
              </div>

              <div v-else-if="column.inputType === 'LIST'">
                <ListExecute
                  :data="column"
                  :form="scope.row"
                  :fromRepeatable="true"
                  :is-view="true"
                  :fieldsData="tableHeaders"
                ></ListExecute>
              </div>
              <div
                v-else-if="
                  column.inputType == 'FORMULA' &&
                  !checkhideFields(column, scope.row.indexKey)
                "
              >
                <FormulaExecute
                  :data="column"
                  :form="scope.row"
                  :fromRepeatable="true"
                  :is-view="true"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></FormulaExecute>
              </div>
              <div v-else-if="column.inputType == 'ENTITY'">
                <EntityExecute
                  :data="column"
                  :form="scope.row"
                  :is-view="true"
                  :fieldsData="tableHeaders"
                  :fromRepeatable="true"
                  @entityDataUpdated="emitDataToEntityVariables"
                  @clearEntityFields="emitUnsetEntityVariables"
                ></EntityExecute>
              </div>
              <div v-else-if="column.inputType == 'FILE' &&
                  scope.row.indexKey &&
                  !checkhideFields(column, scope.row.indexKey)
                ">
                <FileExecute
                    :data="column"
                    :form="scope.row"
                    :is-view="true"
                    :fromRepeatable="true"
                    :fieldsData="tableHeaders"
                    :hasLabel="!hasLabel"
                    :isFromRepeatable="true"
                    :filesInfo="filesInfo"
                    :isView="true"
                ></FileExecute>
              </div>
              <div v-else-if="column.inputType == 'CHECKBOX_GROUP'">
                <CheckBoxGroupExecute
                  :data="column"
                  :form="scope.row"
                  :is-view="true"
                  :fromRepeatable="true"
                  :fieldsData="tableHeaders"
                  :hasLabel="hasLabel"
                  :singleCompanyTemplateCheckBoxArray="
                    singleCompanyTemplateCheckBoxArray
                  "
                ></CheckBoxGroupExecute>
              </div>
              <div v-else-if="column.inputType === 'RADIO_BUTTON_GROUP'">
                <RadioButtonGroupExecute
                  :data="column"
                  :form="scope.row"
                  :is-view="true"
                  :fromRepeatable="true"
                  :fieldsData="tableHeaders"
                  :hasLabel="hasLabel"
                  :checkIsDisabled="checkIsDisabled(column, scope.row.indexKey)"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></RadioButtonGroupExecute>
              </div>

              <div
                v-else-if="column.inputType == 'CHECKBOX' && column.group_key"
              >
                <CheckBoxExecute
                  :data="column"
                  :form="scope.row"
                  :is-view="true"
                  :fromRepeatable="true"
                  :fieldsData="tableHeaders"
                  :hasLabel="hasLabel"
                  :singleCompanyTemplateCheckBoxArray="
                    singleCompanyTemplateCheckBoxArray
                  "
                ></CheckBoxExecute>
              </div>
              <div v-else-if="column.inputType == 'WEEKDAYS'">
                <WeekDaysExecute
                  :data="column"
                  :form="scope.row"
                  :hasLabel="hasLabel"
                  :is-view="true"
                  :fromRepeatable="true"
                  :fieldsData="tableHeaders"
                >
                </WeekDaysExecute>
              </div>
              <div v-else-if="column.inputType == 'STAR_RATING' &&
               scope.row.indexKey &&
               !checkhideFields(column, scope.row.indexKey)">
                <StarRatingExecute
                  :data="column"
                  :form="scope.row"
                  :hasLabel="!hasLabel"
                  :is-view="true"
                  :fromRepeatable="true"
                  :fieldsData="tableHeaders"
                >
                </StarRatingExecute>
              </div>
              <div v-else-if="column.inputType == 'CONCATENATE'  &&
              scope.row.indexKey &&
              !checkhideFields(column, scope.row.indexKey)">
                <ConcatenateExecute
                  :data="column"
                  :form="scope.row"
                  :fromRepeatable="true"
                  :is-view="true"
                  :fieldsData="tableHeaders"
                  :checkIsDisabled="checkIsDisabled(column, scope.row.indexKey)"
                  @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                ></ConcatenateExecute>
              </div>
              <div v-else-if="column.inputType == 'SIGNATURE'">
                <ESignatureExecute
                    :data="column"
                    :form="scope.row"
                    :hasLabel="!hasLabel"
                    :fromRepeatable="true"
                    :is-view="true"
                    :fieldsData="tableHeaders"
                    :checkIsDisabled="checkIsDisabled(column, scope.row.indexKey)"
                    @applyFormRules="rulesEventEmitter(scope.row.indexKey)"
                  >
                </ESignatureExecute>
              </div>
              <div v-else-if="column.inputType == 'TIME'">
                <TimeExecute
                  :data="column"
                  :form="scope.row"
                  :is-view="true"
                ></TimeExecute>
              </div>
              <div v-else-if="column.inputType == 'TIME_RANGE'">
                <TimeRangeExecute
                  :data="column"
                  :form="scope.row"
                  :is-view="true"
                ></TimeRangeExecute>
              </div>
              <p v-else>{{ scope.row[column.key] }}</p>
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column fixed label="Actions" :min-width="120" v-if="!isView">
          <template slot-scope="scope">
            <el-button class="p-0" round>
              <img
                src="@/assets/img/icons/create.svg"
                alt="icon"
                @click="onEdit(scope.row)"
                width="15"
              />
            </el-button>
            <el-button class="p-0" round>
              <img
                src="@/assets/img/icons/delete.png"
                alt="icon"
                @click="onDelete(scope.row)"
                width="15"
              />
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="this.isOn == true">
        <el-row>
          <el-col :lg="24" :md="24" :sm="12" :xs="8">
            <el-card
              class="kanban-card"
              shadow="hover"
              v-for="(entity, index) in entitiesData"
              :key="index"
            >
              <el-scrollbar
                wrap-style="max-height: 400px;"
                style="border-width: 0px"
              >
                <div
                  :style="
                    kanban_index == index
                      ? ''
                      : 'display: grid; grid-template-columns: 50% 50%'
                  "
                >
                  <div
                    class="kanban-card-column"
                    v-for="column in tableHeaders"
                    :key="column.label"
                    :prop="column.key"
                  >
                    <div v-if="kanban_index == index">
                      <!-- {{ column.inputType }} -->
                      <div
                        v-if="
                          column.inputType == 'DATE' &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <h5 class="kanban-card-content">{{ column.label }}</h5>
                        <el-date-picker
                          placeholder="Select date"
                          class="smaller-than"
                          size="large"
                          v-model="selectedRow[column.key]"
                          :format="
                            getDefaultDateFormat &&
                            getDefaultDateFormat.includes('Do') == false
                              ? getDefaultDateFormat
                                  .replace('YYYY', 'yyyy')
                                  .replace('DD', 'dd')
                              : 'MM-dd-yyyy'
                          "
                          :disabled="checkIsDisabled(column, entity.indexKey)"
                          :style="{ width: '78%' }"
                          @change="rulesEventEmitter(entity.indexKey)"
                        >
                        </el-date-picker>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'TIME' &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <!-- <h5 class="kanban-card-content">{{ column.label }}</h5> -->
                        <TimeExecute
                          :data="column"
                          :form="selectedRow"
                          :is-view="false"
                          :hasLabel="hasLabel"
                          :checkIsDisabled="checkIsDisabled(column)"
                          @applyFormRules="
                            rulesEventEmitter(scope.row.indexKey)
                          "
                        ></TimeExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'TIME_RANGE' &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <!-- <h5 class="kanban-card-content">{{ column.label }}</h5> -->
                        <TimeRangeExecute
                          :data="column"
                          :form="selectedRow"
                          :is-view="false"
                          :hasLabel="hasLabel"
                          :checkIsDisabled="checkIsDisabled(column)"
                          @applyFormRules="
                            rulesEventEmitter(scope.row.indexKey)
                          "
                        ></TimeRangeExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'DATE_TIME' &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <h5 class="kanban-card-content">{{ column.label }}</h5>
                        <el-date-picker
                          class="smaller-than"
                          size="large"
                          prefix-icon="el-icon-date"
                          v-model="selectedRow[column.key]"
                          type="datetime"
                          placeholder="Select Date Time"
                          :format="'MM-dd-yyyy hh:mm:ss'"
                          :disabled="checkIsDisabled(column, entity.indexKey)"
                          :style="{ width: '78%' }"
                          @change="rulesEventEmitter(entity.indexKey)"
                        >
                        </el-date-picker>
                      </div>

                      <div
                        v-else-if="
                          column.inputType === 'DATE_TIME_RANGE' &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <h5 class="kanban-card-content">{{ column.label }}</h5>
                        <DateTimeRangeExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                          :style="{ width: '78%' }"
                          :fieldsData="tableHeaders"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></DateTimeRangeExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'DATE_RANGE' &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <h5 class="kanban-card-content">{{ column.label }}</h5>
                        <DateRangeExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                          :style="{ width: '78%' }"
                          :fieldsData="tableHeaders"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></DateRangeExecute>
                      </div>


                      <div
                        v-else-if="
                          column.inputType == 'MULTI_SELECT' &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <h5 class="kanban-card-content">{{ column.label }}</h5>
                        <el-select
                        collapse-tags 
                          placeholder="Please select..."
                          :clearable="true"
                          :filterable="true"
                          :multiple="
                            column.inputType == 'MULTI_SELECT' ? true : false
                          "
                          :allow-create="true"
                          :default-first-option="true"
                          v-model="selectedRow[column.key]"
                          :disabled="
                            (column.inputType === 'MULTI_SELECT' &&
                              (!column.options || !column.options.length)) ||
                            checkIsDisabled(column, entity.indexKey)
                          "
                          @change="rulesEventEmitter(entity.indexKey)"
                          :style="{ width: '78%' }"
                        >
                          <el-option
                            v-for="op in column.options"
                            :key="op"
                            :label="op"
                            :value="op"
                          ></el-option>
                        </el-select>
                      </div>
                      <div
                        v-else-if="
                          column.inputType == 'CHECKBOX' && !column.group_key
                        "
                      >
                        <h5 class="kanban-card-content">{{ column.label }}</h5>
                        <el-checkbox v-model="selectedRow[column.key]">
                        </el-checkbox>
                      </div>
                      <!-- <div v-else-if="column.inputType === 'CHECKBOX_GROUP' && column.group_key">
                <CheckBoxGroupExecute
                  :data="column"
                  :form="selectedRow"
                  :fromRepeatable="true"
                  :is-view="false"
                  :fieldsData="tableHeaders"
                ></CheckBoxGroupExecute>
              </div> -->
                      <div
                        v-else-if="
                          column.inputType == 'CURRENCY' &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <h5 class="kanban-card-content">{{ column.label }}</h5>
                        <CurrencyExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                          :style="{ width: '78%' }"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></CurrencyExecute>
                      </div>
                      <div v-else-if="column.inputType == 'PAYMENT_BUTTON'">
                        <h5 class="kanban-card-content">{{ column.label }}</h5>
                        <PaymentExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                        ></PaymentExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType == 'FORMULA' &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <h5 class="kanban-card-content">{{ column.label }}</h5>
                        <FormulaExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></FormulaExecute>
                      </div>
                      <div v-else-if="column.inputType == 'ENTITY'">
                        <h5 class="kanban-card-content">{{ column.label }}</h5>
                        <EntityExecute
                          :data="column"
                          :form="selectedRow"
                          :is-view="false"
                          :fieldsData="tableHeaders"
                          :fromRepeatable="true"
                          @entityDataUpdated="emitDataToEntityVariables"
                          @clearEntityFields="emitUnsetEntityVariables"
                        ></EntityExecute>
                      </div>
                      <div v-else-if="column.inputType === 'CHECKBOX_GROUP'">
                        <h5 class="kanban-card-content">{{ column.label }}</h5>
                        <CheckBoxGroupExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                          :fieldsData="tableHeaders"
                          :hasLabel="hasLabel"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></CheckBoxGroupExecute>
                      </div>
                      <div
                        v-else-if="column.inputType === 'RADIO_BUTTON_GROUP'"
                      >
                        <RadioButtonGroupExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                          :fieldsData="tableHeaders"
                          :hasLabel="hasLabel"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></RadioButtonGroupExecute>
                      </div>
                      <div v-else-if="column.inputType == 'FILE'">
                        <h5 class="kanban-card-content">{{ column.label }}</h5> 
                        <FileExecute
                          :data="column"
                          :form="selectedRow"
                          :is-view="false"
                          :fromRepeatable="true"
                          :fieldsData="tableHeaders"
                          :hasLabel="hasLabel"
                          :filesInfo="filesInfo"
                          :isFromRepeatable="true"
                        ></FileExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType == 'CHECKBOX' &&
                          column.group_key &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <CheckBoxExecute
                          :data="column"
                          :form="selectedRow"
                          :is-view="false"
                          :fromRepeatable="true"
                          :fieldsData="tableHeaders"
                          :style="{ width: '78%' }"
                          :hasLabel="hasLabel"
                          :singleCompanyTemplateCheckBoxArray="
                            singleCompanyTemplateCheckBoxArray
                          "
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                        ></CheckBoxExecute>
                      </div>
                      <div v-else-if="column.inputType == 'WEEKDAYS'">
                        <h5 class="kanban-card-content">{{ column.label }}</h5> 
                        <WeekDaysExecute
                          :data="column"
                          :form="selectedRow"
                          :hasLabel="hasLabel"
                          :is-view="false"
                          :fromRepeatable="true"
                          :fieldsData="tableHeaders"
                        >
                        </WeekDaysExecute>
                      </div>
                      <div v-else-if="column.inputType == 'YES_OR_NO' &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        ">
                        <YesOrNoExecute
                          :data="column"
                          :form="selectedRow"
                          :hasLabel="hasLabel"
                          :is-view="false"
                          :fromRepeatable="true"
                          :fieldsData="tableHeaders"
                        >
                        </YesOrNoExecute>
                      </div>
                      <div v-else-if="column.inputType == 'PHONE_COUNTRY_CODE' &&
                        entity.indexKey &&
                        !checkhideFields(column, entity.indexKey)">
                        <PhoneCountryCodeExecute
                          :data="column"
                          :form="selectedRow"
                          :hasLabel="hasLabel"
                          :is-view="false"
                          :fromRepeatable="true"
                          :fieldsData="tableHeaders"
                        >
                        </PhoneCountryCodeExecute>
                      </div>
                      <div v-else-if="column.inputType == 'SIGNATURE' &&
                        entity.indexKey &&
                        !checkhideFields(column, entity.indexKey)">
                        <ESignatureExecute
                          :data="column"
                          :form="selectedRow"
                          :hasLabel="hasLabel"
                          :is-view="false"
                          :fromRepeatable="true"
                          :fieldsData="tableHeaders"
                        >
                        </ESignatureExecute>
                      </div>
                      <div
                        v-else-if="
                          column.inputType === 'SELECT' &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <SelectExecute
                          :data="column"
                          :form="selectedRow"
                          :fromRepeatable="true"
                          :is-view="false"
                          :fieldsData="tableHeaders"
                          :hasLabel="hasLabel"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                          :style="{ width: '78%' }"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        >
                        </SelectExecute>
                      </div>
                      <div
                        v-else-if="
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <h5 class="kanban-card-content">{{ column.label }}</h5>
                        <el-input
                          type="text"
                          v-model="selectedRow[column.key]"
                          style="width: 22vw"
                          :checkIsDisabled="
                            checkIsDisabled(column, entity.indexKey)
                          "
                          :style="{ width: '78%' }"
                          :disabled="checkIsDisabled(column, entity.indexKey)"
                          @change="rulesEventEmitter(entity.indexKey)"
                        ></el-input>
                      </div>
                    </div>
                    <div v-else>
                      <!-- this is view mode we cant edit it-->
                      <h5 class="kanban-card-content kanban-card-content-color">
                        {{ column.label }}
                      </h5>
                      <p v-if="column.inputType == 'DATE'">
                        {{ entity[column.key] | moment("MM-DD-YYYY") }}
                      </p>
                      <p v-else-if="column.inputType == 'DATE_TIME'">
                        {{ entity[column.key] | moment("MM-DD-YYYY HH:mm:ss") }}
                      </p>
                      <p v-else-if="column.inputType == 'DATE_TIME_RANGE'">
                        {{
                          entity[`${column.key}_from`]
                            | moment("MM-DD-YYYY HH:mm:ss")
                        }}
                        (From ~ To)
                        {{
                          entity[`${column.key}_to`]
                            | moment("MM-DD-YYYY HH:mm:ss")
                        }}
                      </p>
                      <p v-else-if="column.inputType == 'DATE_RANGE'">
                        {{
                          entity[`${column.key}_from`]
                            | moment("MM-DD-YYYY ")
                        }}
                        (From ~ To)
                        {{
                          entity[`${column.key}_to`]
                            | moment("MM-DD-YYYY ")
                        }}
                      </p>
                      <p v-else-if="column.inputType == 'MULTI_SELECT'">
                        {{ (entity[column.key] || []).join(", ") }}
                      </p>
                      <div v-else-if="column.inputType == 'CURRENCY'">
                        <CurrencyExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="true"
                          :fieldsData="tableHeaders"
                        ></CurrencyExecute>
                      </div>
                      <div v-else-if="column.inputType == 'PAYMENT_BUTTON'">
                        <PaymentExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="true"
                          :fieldsData="tableHeaders"
                        ></PaymentExecute>
                      </div>
                      <div v-else-if="column.inputType == 'PHONE_COUNTRY_CODE'">
                        <PhoneCountryCodeExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="true"
                          :fieldsData="tableHeaders"
                        >
                        </PhoneCountryCodeExecute>
                      </div>
                      <div v-else-if="(column.inputType == 'SINGLE_LINE_TEXT' ||
                         column.inputType === 'EMAIL' ||
                         column.inputType === 'PASSWORD' ||
                         column.inputType === 'MASKED' ||
                         column.inputType === 'HYPER_LINK')">
                        <SingleLineTextExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="true"
                          :fieldsData="tableHeaders"
                        ></SingleLineTextExecute>
                      </div>
                      <!-- <div v-else-if="column.inputType == 'MULTI_SELECT'">
                
                <MultiSelectExecute
                  :data="column"
                  :form="selectedRow"
                  :fromRepeatable="true"
                  :is-view="false"
                 
                  :fieldsData="tableHeaders"
                ></MultiSelectExecute>
              </div> -->

                      <div
                        v-else-if="
                          column.inputType == 'NUMBER' ||
                          column.inputType == 'PHONE' ||
                          column.inputType == 'ZIP_CODE' ||
                          column.inputType == 'FAX'
                        "
                      >
                        <NumberExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="true"
                          :fieldsData="tableHeaders"
                        ></NumberExecute>
                      </div>

                      <div v-else-if="column.inputType == 'MULTI_LINE_TEXT'">
                        <MultiLineTextExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="true"
                          :fieldsData="tableHeaders"
                        ></MultiLineTextExecute>
                      </div>

                      <div
                        v-else-if="
                          column.inputType === 'SELECT' &&
                          entity.indexKey &&
                          !checkhideFields(column, entity.indexKey)
                        "
                      >
                        <SelectExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="true"
                          :fieldsData="tableHeaders"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></SelectExecute>
                      </div>
                      <div v-else-if="column.inputType === 'LIST'">
                        <ListExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="true"
                          :fieldsData="tableHeaders"
                        ></ListExecute>
                      </div>
                      <div v-else-if="column.inputType == 'ENTITY'">
                        <EntityExecute
                          :data="column"
                          :form="entity"
                          :is-view="true"
                          :fieldsData="tableHeaders"
                          :fromRepeatable="true"
                          @entityDataUpdated="emitDataToEntityVariables"
                          @clearEntityFields="emitUnsetEntityVariables"
                        ></EntityExecute>
                      </div>
                      <div v-else-if="column.inputType == 'FILE'">
                        <FileExecute
                          :data="column"
                          :form="entity"
                          :is-view="true"
                          :fromRepeatable="true"
                          :fieldsData="tableHeaders"
                          :hasLabel="!hasLabel"
                          :filesInfo="filesInfo"
                          :isFromRepeatable="true"
                        ></FileExecute>
                      </div>
                      <div v-else-if="column.inputType == 'CHECKBOX'">
                        <CheckBoxExecute
                          :data="column"
                          :form="entity"
                          :is-view="true"
                          :fromRepeatable="true"
                          :fieldsData="tableHeaders"
                          :hasLabel="hasLabel"
                          :singleCompanyTemplateCheckBoxArray="
                            singleCompanyTemplateCheckBoxArray
                          "
                        ></CheckBoxExecute>
                      </div>
                      <div v-else-if="column.inputType == 'WEEKDAYS'">
                        <WeekDaysExecute
                          :data="column"
                          :form="entity"
                          :hasLabel="hasLabel"
                          :is-view="true"
                          :fromRepeatable="true"
                          :fieldsData="tableHeaders"
                        >
                        </WeekDaysExecute>
                      </div>
                      <div v-else-if="column.inputType == 'STAR_RATING'">
                        <StarRatingExecute
                          :data="column"
                          :form="entity"
                          :hasLabel="!hasLabel"
                          :is-view="true"
                          :isView="true"
                          :fromRepeatable="true"
                          :fieldsData="tableHeaders"
                        >
                        </StarRatingExecute>
                      </div>
                      <div v-else-if="column.inputType == 'CHECKBOX_GROUP'">
                        <CheckBoxGroupExecute
                          :data="column"
                          :form="entity"
                          :is-view="true"
                          :fromRepeatable="true"
                          :fieldsData="tableHeaders"
                          :hasLabel="hasLabel"
                          :singleCompanyTemplateCheckBoxArray="
                            singleCompanyTemplateCheckBoxArray
                          "
                        ></CheckBoxGroupExecute>
                      </div>
                      <div
                        v-else-if="column.inputType === 'RADIO_BUTTON_GROUP'"
                      >
                        <RadioButtonGroupExecute
                          :data="column"
                          :form="entity"
                          :is-view="true"
                          :fromRepeatable="true"
                          :fieldsData="tableHeaders"
                          :hasLabel="hasLabel"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></RadioButtonGroupExecute>
                      </div>
                      <div v-else-if="column.inputType == 'FORMULA'">
                        <FormulaExecute
                          :data="column"
                          :form="entity"
                          :is-view="true"
                          :fromRepeatable="true"
                          @applyFormRules="rulesEventEmitter(entity.indexKey)"
                        ></FormulaExecute>
                      </div>
                      <div v-else-if="column.inputType == 'CONCATENATE'">
                        <ConcatenateExecute
                          :data="column"
                          :form="entity"
                          :fromRepeatable="true"
                          :is-view="true"
                          :fieldsData="tableHeaders"
                        ></ConcatenateExecute>
                      </div>
                     <div v-else-if="column.inputType == 'SIGNATURE'">
                       <ESignatureExecute
                       :data="column"
                       :form="entity"
                       :fromRepeatable="true"
                       :is-view="true"
                       :fieldsData="tableHeaders"
                       :hasLabel="!hasLabel"
                       >
                      </ESignatureExecute>
                     </div>
                      <div v-else-if="column.inputType == 'TIME'">
                        <TimeExecute
                          :data="column"
                          :form="entity"
                          :is-view="true"
                        ></TimeExecute>
                      </div>
                      <div v-else-if="column.inputType == 'TIME_RANGE'">
                        <TimeRangeExecute
                          :data="column"
                          :form="entity"
                          :is-view="true"
                        ></TimeRangeExecute>
                      </div>
                      <p v-else>{{ entity[column.key] || "-" }}</p>

                      <!-- <p class="kanban-card-content" v-if="entity[column.key]">{{entity[column.key]}}</p>
                            <p v-else>-</p> -->
                    </div>
                  </div>
                </div>
              </el-scrollbar>
              <div class="kanban-footer" v-if="!isView">
                <el-button
                  type="text"
                  v-if="kanban_index == index"
                  @click="saveChanges"
                  style="color: #67c23a"
                  >Save
                </el-button>
                <el-button
                  type="text"
                  v-if="kanban_index == index"
                  @click="cancelChanges"
                  >Cancel
                </el-button>
                <el-button
                  type="text"
                  v-if="kanban_index != index"
                  @click="editKanban(index)"
                  >Edit</el-button
                >
                <el-button
                  class="kanban-footer-button"
                  type="text"
                  @click="deleteKanban(entity)"
                  v-if="kanban_index != index"
                  >Delete</el-button
                >
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <!-- ADD DATA MODE OPEN -->
      <el-dialog title="Add Data" :visible.sync="kanbanDialog" width="35%">
        <el-scrollbar
          wrap-style="max-height: 400px;padding:13px;"
          style="border-width: 0px"
        >
          <div
            class="d-flex flex-column justify-content-center"
            v-for="column in tableHeaders"
            :key="column.label"
            :prop="column.key"
          >
            <div v-if="column.inputType == 'DATE'">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <el-date-picker
                placeholder="Select date"
                class="smaller-than"
                size="large"
                v-model="selectedRow[column.key]"
                :format="
                  getDefaultDateFormat &&
                  getDefaultDateFormat.includes('Do') == false
                    ? getDefaultDateFormat
                        .replace('YYYY', 'yyyy')
                        .replace('DD', 'dd')
                    : 'MM-dd-yyyy'
                "
                :style="{ width: '78%' }"
              >
              </el-date-picker>
            </div>
            <div v-else-if="column.inputType === 'TIME'">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <TimeExecute
                :data="column"
                :form="selectedRow"
                :is-view="false"
              ></TimeExecute>
            </div>
            <div v-else-if="column.inputType === 'TIME_RANGE'">
              <!-- <h5 class="kanban-card-content">{{ column.label }}</h5> -->
              <TimeRangeExecute
                :data="column"
                :form="selectedRow"
                :is-view="false"
              ></TimeRangeExecute>
            </div>
            <div v-else-if="column.inputType === 'DATE_TIME'">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <el-date-picker
                class="smaller-than"
                size="mini"
                prefix-icon="el-icon-date"
                v-model="selectedRow[column.key]"
                type="datetime"
                placeholder="Select Date Time"
                :format="'MM-dd-yyyy HH:mm:ss'"
              >
              </el-date-picker>
            </div>

            <div v-else-if="column.inputType === 'DATE_TIME_RANGE'">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <DateTimeRangeExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :is-view="false"
                :fieldsData="tableHeaders"
                :style="{ width: '78%' }"
              ></DateTimeRangeExecute>
              
              <!-- <el-date-picker
                class="smaller-than"
                size="mini"
                prefix-icon="el-icon-date"
                v-model="selectedRow[column.key]"
                type="datetimerange"
                 range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date"
                popper-class="my-popover"
                placeholder="Select Date Time"
                :format="'MM-dd-yyyy hh:mm:ss'"
              >
              </el-date-picker> -->
            </div>
            <div v-else-if="column.inputType === 'DATE_RANGE'">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <DateRangeExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :is-view="false"
                :fieldsData="tableHeaders"
                :style="{ width: '78%' }"
              ></DateRangeExecute>
              </div>
            <div v-else-if="column.inputType == 'MULTI_SELECT'">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <el-select
                placeholder="Please select..."
                :clearable="true"
                :filterable="true"
                :style="{ width: '78%' }"
                :multiple="column.inputType == 'MULTI_SELECT' ? true : false"
                :allow-create="true"
                :default-first-option="true"
                v-model="selectedRow[column.key]"
                :disabled="
                  column.inputType == 'MULTI_SELECT' &&
                  column.options &&
                  !column.options.length
                "
                collapse-tags
              >
                <el-option
                  v-for="op in column.options"
                  :key="op"
                  :label="op"
                  :value="op"
                ></el-option>
              </el-select>
            </div>
            <div
              v-else-if="column.inputType == 'CHECKBOX' && !column.group_key"
            >
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <el-checkbox v-model="selectedRow[column.key]"> </el-checkbox>
            </div>

            <div v-else-if="column.inputType == 'ENTITY'">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <EntityExecute
                :data="column"
                :form="selectedRow"
                :is-view="false"
                :fieldsData="tableHeaders"
                :fromRepeatable="true"
                @entityDataUpdated="emitDataToEntityVariables"
                @clearEntityFields="emitUnsetEntityVariables"
              ></EntityExecute>
            </div>
            <div v-else-if="column.inputType === 'RADIO_BUTTON_GROUP'">
              <RadioButtonGroupExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
                :fieldsData="tableHeaders"
                :hasLabel="hasLabel"
              ></RadioButtonGroupExecute>
            </div>
            <div v-else-if="column.inputType === 'CHECKBOX_GROUP'">
              <h5 class="kanban-card-content">{{ column.label }}</h5> 
              <CheckBoxGroupExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
                :fieldsData="tableHeaders"
                :hasLabel="hasLabel"
              ></CheckBoxGroupExecute>
            </div>
            <div v-else-if="column.inputType == 'FILE'">
              <FileExecute
                :data="column"
                :form="selectedRow"
                :isView="false"
                :fromRepeatable="true"
                :fieldsData="tableHeaders"
                :hasLabel="hasLabel"
                :isFromRepeatable="true"
                :filesInfo="filesInfo"
              ></FileExecute>
            </div>
            <div v-else-if="column.inputType == 'CHECKBOX' && column.group_key">
              <CheckBoxExecute
                :data="column"
                :form="selectedRow"
                :isView="false"
                :fromRepeatable="true"
                :fieldsData="tableHeaders"
                :hasLabel="hasLabel"
                :style="{ width: '78%' }"
                :singleCompanyTemplateCheckBoxArray="
                  singleCompanyTemplateCheckBoxArray
                "
              ></CheckBoxExecute>
            </div>
            <div v-else-if="column.inputType == 'WEEKDAYS'">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <WeekDaysExecute
                :data="column"
                :form="selectedRow"
                :isView="false"
                :fromRepeatable="true"
                :fieldsData="tableHeaders"
                :hasLabel="hasLabel"
              >
              </WeekDaysExecute>
            </div>
            <div v-else-if="column.inputType == 'CURRENCY'">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <CurrencyExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
                :style="{ width: '78%' }"
              ></CurrencyExecute>
            </div>
            <div v-else-if="column.inputType == 'PAYMENT_BUTTON'">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <PaymentExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
              ></PaymentExecute>
            </div>
            <div v-else-if="column.inputType == 'YES_OR_NO'">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
                <YesOrNoExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
                :fieldsData="tableHeaders"
              ></YesOrNoExecute>
            </div>
            <div v-if="column.inputType === 'SIGNATURE'">
               <h5 class="kanban-card-content">{{ column.label }}</h5>
                <ESignatureExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
                :fieldsData="tableHeaders">
                </ESignatureExecute>
            </div>
            <div v-if="column.input_type === 'PHONE_COUNTRY_CODE'">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <PhoneCountryCodeExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
                :fieldsData="tableHeaders">
              </PhoneCountryCodeExecute>
            </div>
            <div v-if="column.input_type === 'STAR_RATING'">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <StarRatingExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
                :hasLabel="hasLabel"
                :fieldsData="tableHeaders">
              </StarRatingExecute>
            </div>
            <div v-else-if="(column.inputType === 'SINGLE_LINE_TEXT' ||
                    column.inputType === 'EMAIL' ||
                    column.inputType === 'PASSWORD' ||
                    column.inputType === 'MASKED' ||
                    column.inputType === 'HYPER_LINK')">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <SingleLineTextExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
                :style="{ width: '78%' }"
              ></SingleLineTextExecute>
            </div>
            <!-- <div v-else-if="column.inputType == 'MULTI_SELECT'">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <MultiSelectExecute
                  :data="column"
                  :form="selectedRow"
                  :fromRepeatable="true"
                  :isView="false"
                ></MultiSelectExecute>
            </div> -->

            <div
              v-else-if="
                column.inputType == 'NUMBER' ||
                column.inputType == 'PHONE' ||
                column.inputType == 'ZIP_CODE' ||
                column.inputType == 'FAX'
              "
            >
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <NumberExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
                :style="{ width: '78%' }"
              ></NumberExecute>
            </div>

            <div v-else-if="column.inputType == 'MULTI_LINE_TEXT'">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <MultiLineTextExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
                :style="{ width: '78%' }"
              ></MultiLineTextExecute>
            </div>

            <div v-else-if="column.inputType === 'SELECT'">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <SelectExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
                :style="{ width: '78%' }"
              ></SelectExecute>
            </div>

            <div v-else-if="column.inputType === 'LIST'">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <ListExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
              ></ListExecute>
            </div>
            <div v-else-if="column.inputType === 'STAR_RATING'">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <StarRatingExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
              ></StarRatingExecute>
            </div>
            <div v-else-if="column.inputType == 'FORMULA'">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <FormulaExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
              ></FormulaExecute>
            </div>
            <div v-else-if="column.inputType == 'CONCATENATE'">
              <h5 class="kanban-card-content">{{ column.label }}</h5>
              <ConcatenateExecute
                :data="column"
                :form="selectedRow"
                :fromRepeatable="true"
                :isView="false"
                :style="{ width: '78%' }"
              ></ConcatenateExecute>
            </div>
            <div v-else>
              <!-- <h5 class="kanban-card-content">{{ column.label }}</h5>
              <el-input
                type="text"
                v-model="selectedRow[column.key]"
              ></el-input> -->
            </div>
            <br />
          </div>
        </el-scrollbar>
        <span slot="footer" class="dialog-footer">
          <el-button @click="kanbanDialog = false">Cancel</el-button>
          <el-button type="primary" @click="kabanAdd">Add</el-button>
        </span>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
// import moment from "moment";
// import CurrencyInput from "./../vue-weblineindia-currency-input/src/component";
export default {
  name: "templates-RepeatableTemplateExecute",
  props: [
    "templateData",
    "entityRepeatableTemplateData",
    "baseWidth",
    "form",
    "isSubmit",
    "entitiesData",
    "isView",
    "getDefaultDateFormat",
    "singleCompanyTemplateCheckBoxArray",
    "max_value",
    "min_value",
    "templateRules",
    "templateRuleFields",
  ],
  mixins: [TemplateBuilderHelper],
  async mounted() {
    if (
      this.entityRepeatableTemplateData &&
      this.entityRepeatableTemplateData.default_display == "KANBAN"
    ) {
      this.isOn = true;
    }
    this.loading = true;
    await Promise.all([this.setTemplateData(), this.getRepeatableIds()]);
    this.loading = false;
  },
  computed: {
    getTableStyles() {
      return "width:" + this.currentPageBaseWidth + "px;";
    },
    ...mapGetters("templatesData", [
      "getRepeatableTemplateIds",
      "getFileUploadURL",
    ]),
  },
  data() {
    return {
      newEntityData: {},
      kanban_index: -1,
      kanbanDialog: false,
      isOn: false,
      loading: false,
      hasLabel: true,
      tableHeaders: [],
      repeatableTemplateTitle: "",
      // entitiesData: [],
      lastFieldValueY: null,
      currentPageBaseWidth: 900,
      selectedRow: {},
      allFormData: [],
      isNewDataAdded: false,
      selectedRowIndex: null,
      isEditingData: false,
      repeatableIds: [],
      isEditingKanban: false,
      mappedTemplateData: {},
      hideFields: {},
      disabledFields: {},
      showTemplateFields: {},
      filesInfo: {}
    };
  },
  components: {
    FormulaExecute: () => import("./formComponentsExecute/FormulaExecute"),
    EntityExecute: () => import("./formComponentsExecute/EntityExecute"),
    FileExecute: () => import("./formComponentsExecute/FileExecute"),
    CheckBoxExecute: () => import("./formComponentsExecute/CheckBoxExecute"),
    CurrencyExecute: () => import("./formComponentsExecute/CurrencyExecute"),
    PaymentExecute: () => import("./formComponentsExecute/PaymentExecute"),
    SingleLineTextExecute: () =>
      import("./formComponentsExecute/SingleLineTextExecute"),
    SelectExecute: () => import("./formComponentsExecute/SelectExecute"),
    ListExecute: () => import("./formComponentsExecute/ListExecute"),
    DateTimeRangeExecute: () =>
      import("./formComponentsExecute/DateTimeRangeExecute"),
    DateRangeExecute: () =>
      import("./formComponentsExecute/DateRangeExecute"),
    CheckBoxGroupExecute: () =>
      import("./formComponentsExecute/CheckBoxGroupExecute"),
    //RadioButtonGroupExecute
    RadioButtonGroupExecute: () =>
      import("./formComponentsExecute/RadioButtonGroupExecute"),
    MultiLineTextExecute: () =>
      import("./formComponentsExecute/MultiLineTextExecute"),
    NumberExecute: () => import("./formComponentsExecute/NumberExecute"),
    MultiSelectExecute: () =>
      import("./formComponentsExecute/MultiSelectExecute"),
    WeekDaysExecute: () =>
      import("./formComponentsExecute/WeekDaysExecute.vue"),
    TimeExecute: () => import("./formComponentsExecute/TimeExecute.vue"),
    TimeRangeExecute: () =>
      import("./formComponentsExecute/TimeRangeExecute.vue"),
    StarRatingExecute: () =>
      import("./formComponentsExecute/StarRatingExecute.vue"),
    ConcatenateExecute: () =>
      import("./formComponentsExecute/ConcatenateExecute"),
    YesOrNoExecute: () =>
      import("./formComponentsExecute/YesOrNoExecute"),
    PhoneCountryCodeExecute: () =>
      import("./formComponentsExecute/PhoneCountryCodeExecute.vue"),
    ESignatureExecute: () =>
      import("./formComponentsExecute/ESignatureExecute.vue"),
    CalenderExecute :() => import("./formComponentsExecute/CalenderExecute.vue"),
    IconExecute :() => import("./formComponentsExecute/IconExecute.vue"),
    RandomTextExecute: () => import("./formComponentsExecute/RandomTextExecute.vue"),
  },
  methods: {
    checkIsDisabled(field, indexKey) {
      {
        {
          field, indexKey;
        }
      }
      if (
        this.templateRuleFields &&
        this.templateRuleFields.disabledFields &&
        this.templateRuleFields.disabledFields[indexKey]
      ) {
        return this.templateRuleFields.disabledFields[indexKey].find(
          (e) => e == field.key
        )
          ? true
          : false;
      }
      return false;
    },
    checkhideFields(field, indexKey) {
      if (
        this.templateRuleFields.hideFields &&
        this.templateRuleFields.hideFields[indexKey]
      ) {
        return this.templateRuleFields.hideFields[indexKey].find(
          (e) => e == field.key
        )
          ? true
          : false;
      }
      return false;
    },
    rulesEventEmitter(indexKey) {
      if (indexKey) {
        let rowIndex = this.entitiesData.findIndex(
          (e) => e.indexKey === indexKey
        );
        if (rowIndex > -1) {
          this.applyRulesOnRepeatableData(
            this.tableHeaders,
            this.templateRules,
            rowIndex,
            indexKey
          );
        }
      }
    },
    async uploadFile(file) {
      file.forEach(async (el) => {
        await this.getFileUploadUrl(el);
        await this.uploadSelectedDocument(el.raw);
        if (this.getUploadedFileData) {
          return (
            this.getUploadedFileData.slug + "/" + this.getUploadedFileData.key
          );
        } else {
          return "";
        }
      });
    },
    async getFileUploadUrl(file) {
      let data = {
        file_name: file.raw.name,
        file_type: file.raw.type,
        file_size_in_kb: file.raw.size / 1000,
      };

      await this.$store.dispatch("templatesData/fetchDocumentUploadUrl", data);
    },
    async uploadSelectedDocument(file) {
      this.loadingText = "Uploading Document...";
      // uploding to S3
      let params = {
        file: file,
        upload_url: this.getFileUploadURL,
      };
      await this.$store.dispatch(
        "templatesData/uploadDocumentToGeneratedUrl",
        params
      );

      if (this.getDocumentUploadStatus) {
        // UPDATEING STATUS
        let params = {
          file_ref_id: this.getFileUploadRefId,
        };
        await this.$store.dispatch(
          "templatesData/updateDocumentUploadStatus",
          params
        );
        if (this.getDocumentUploadStatusUpdated) {
          console.log("fileuploaded");
        }
        // else {
        //   this.$message("Sorry ! Error while file uploading");
        // }
      } else {
        this.$message("Sorry ! Error while file uploading");
      }
    },
    async prepareFormData() {
      this.preparingFormDataLoading = true;
      this.loading = true;
      this.loading = "Preparing template data...";
      for (let [key, value] of Object.entries(this.filesInfo)) {
        if (
          value &&
          typeof value == "object" &&
          value.type == "DOCUMENT" &&
          value.new == true &&
          value.files
        ) {
          this.loading = true;
          this.loadingText = "Uploading files...";
          let path = await this.uploadFile(value.files);
          this.filesInfo[key].path = path;
          this.filesInfo[key].new = false;
          this.form[key] = this.filesInfo[key];
        }
      }
      this.loading = false;

      this.preparingFormDataLoading = false;
      return this.form;
    },
    emitUnsetEntityVariables(parent) {
      this.tableHeaders.forEach((field) => {
        if (field.relationship_key == parent.key) {
          this.$set(this.selectedRow, field.key, "");
        }
      });
    },
    emitDataToEntityVariables(data, parent, label) {
      if (label) {
        this.$set(this.selectedRow, parent.key + "/name", label);
      }
      this.tableHeaders.forEach((field) => {
        if (field.relationship_key == parent.key && data.entityData) {
          if (field.global_variable_entity_field) {
            let templateId = "";
            let key = "";
            let value = "";
            if (field.global_variable_entity_field.includes("#")) {
              templateId = field.global_variable_entity_field.split("#")[0];
              key = field.global_variable_entity_field.split("#")[1];
            } else {
              templateId = field.global_variable_entity_field;
            }
            if (
              data.entityData &&
              data.entityData[templateId] &&
              data.entityData[templateId][key]
            ) {
              value = data.entityData[templateId][key];
            }
            if (this.selectedRow[field.key]) {
              this.selectedRow[field.key] = value;
            } else {
              this.$set(this.selectedRow, field.key, value);
            }
          }
        }
      });
    },
    cancelChanges() {
      this.selectedRow = {};
      this.kanban_index = -1;
    },
    saveChanges() {
      if (this.kanban_index > -1) {
        this.entitiesData[this.kanban_index] = { ...this.selectedRow };
        this.kanban_index = -1;
      }
    },
    kabanAdd() {
      if (this.max_value > this.entitiesData.length) {
        let key = (Math.random() + 1).toString(36).substring(7);
        let objKey = this.getRepeatableTemplateIds.find((key) => {
          let freeIndexKey = this.entitiesData.find(
            (e) => e && e.indexKey == key
          );
          if (!freeIndexKey) {
            return key;
          }
        });
        if (objKey) {
          key = objKey;
        }
        this.entitiesData.push({ ...this.selectedRow, ...{ indexKey: key } });
        this.kanbanDialog = false;
        this.$notify({
          title: "Success",
          message: "Data added successfully",
          type: "success",
        });
      } else {
        this.$notify({
          title: "Warning",
          message: "Max number of records already added. Can't add new data.",
          type: "warning",
        });
        this.kanbanDialog = false;
      }
    },
    editKanban(index) {
      this.kanban_index = index;
      const choosenElement = JSON.parse(
        JSON.stringify(this.entitiesData[index])
      );
      this.selectedRow = { ...{}, ...choosenElement };
      this.isEditingKanban = true;
    },
    deleteKanban(data) {
      this.$confirm("Are you sure to delete? ", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async () => {
        let index = this.entitiesData.findIndex(
          (e) => e.indexKey == data.indexKey
        );
        this.entitiesData.splice(index, 1);
        this.$notify({
          title: "Success",
          message: "Data deleted successfully",
          type: "success",
        });
      });
    },

    kanbanpop() {
      if (this.max_value <= this.entitiesData.length) {
        this.$notify({
          title: "Warning",
          message: "Max number of records already added. Can't add new data.",
          type: "warning",
        });
        return;
      }
      this.tableHeaders.forEach((e) => {
        this.$set(this.selectedRow, e.key, "");
      });
      this.kanbanDialog = true;
    },
    async getRepeatableIds() {
      this.repeatableIds = [];
      await this.$store.dispatch(
        "templatesData/fetchRepeatableTemplateIds",
        this.max_value
      );
    },
    async resetSelectedRow() {
      await (Object.keys(this.selectedRow) || []).forEach((key) => {
        if (this.selectedRowIndex != null) {
          let index = this.entitiesData.findIndex(
            (e) => e && e.indexKey == this.selectedRowIndex
          );
          if (this.entitiesData[index] && key != "indexKey") {
            this.entitiesData[index][key] = this.selectedRow[key];
          }
        }
        this.selectedRow[key] = "";
      });
    },
    async addRowToDataTable() {
      this.isNewDataAdded = true;
      if (this.max_value > this.entitiesData.length) {
        let key = (Math.random() + 1).toString(36).substring(7);
        if (
          this.getRepeatableTemplateIds &&
          this.getRepeatableTemplateIds.length
        ) {
          let objKey = this.getRepeatableTemplateIds.find((key) => {
            let freeIndexKey = this.entitiesData.find(
              (e) => e && e.indexKey == key
            );
            if (!freeIndexKey) {
              return key;
            }
          });
          if (objKey) {
            key = objKey;
          }
        }
        await this.resetSelectedRow();
        this.selectedRow = { ...this.selectedRow, ...{ indexKey: key } };
        this.entitiesData.push({ ...this.selectedRow, ...{ indexKey: key } });
        this.selectedRowIndex = key;
        this.applyRulesOnRepeatableData(
          this.tableHeaders,
          this.templateRules,
          this.entitiesData.length - 1,
          key
        );
      } else {
        this.$notify({
          title: "Warning",
          message: "Max number of records already added. Can't add new data.",
          type: "warning",
        });
      }
    },
    async setTemplateData() {
      this.selectedRow = {};
      (Object.keys(this.form) || []).forEach((e) =>
        this.$set(this.selectedRow, e, "")
      );
      this.repeatableTemplateTitle =
        this.entityRepeatableTemplateData &&
        this.entityRepeatableTemplateData.senderAlias
          ? this.entityRepeatableTemplateData.senderAlias
          : this.templateData.name;
      this.tableHeaders = [];
      let fields = [];
      this.templateData.sections.forEach((section) => {
        if (section.fields && section.fields.length) {
          fields = [...fields, ...section.fields];
        }
      });
      let fieldsInorder = [];
      await fields.forEach((e) => {
        if (e) {
          if (e.height && e.y + e.height > this.lastFieldValueY) {
            this.lastFieldValueY = e.y + e.height;
          }
          if (e.type == "FIELD") {
            fieldsInorder.push(e);
          }
        }
      });
      this.tableHeaders = fieldsInorder.sort((a, b) => a.y - b.y);
      if (this.baseWidth && this.baseWidth > this.currentPageBaseWidth) {
        this.currentPageBaseWidth = this.baseWidth * (75 / 100);
      }
    },
    async hideEdit() {
      let index = -1;
      if (this.selectedRowIndex != null) {
        index = this.entitiesData.findIndex(
          (e) => e && e.indexKey == this.selectedRowIndex
        );
      }
      await (Object.keys(this.selectedRow) || []).forEach((key) => {
        if (this.selectedRowIndex != null && index != -1) {
          if (this.entitiesData[index] && key != "indexKey") {
            this.entitiesData[index][key] = this.selectedRow[key];
          }
        }
      });
      //   this.selectedRowIndex = null;
    },
    async onEdit(data) {
      await this.resetSelectedRow();
      await (Object.keys(data) || []).forEach((e) => {
        if (e != "indexKey") {
          this.selectedRow[e] = data[e];
        }
      });
      this.selectedRowIndex = data.indexKey;
    },
    onDelete(data) {
      let index = this.entitiesData.findIndex(
        (e) => e.indexKey == data.indexKey
      );
      this.entitiesData.splice(index, 1);
    },
  },
};
</script>
<style>
.el-table .cell {
  word-break: break-word;
}
.el-table .td {
  word-wrap: break-word;
}
</style>
<style lang="scss" scoped>
// .el-textarea{
//   height: 10px !important;
//   width: 10px !important;
// }
.kanban-card {
  width: 420px;
  margin: 10px;
  display: inline-block;
  border: 1px solid #cfd4d1;
}
.kanban-card-column {
  // display: inline-block;
  word-wrap: break-word;
  margin: 10px;
  padding: 5px;
}
.kanban-card-content {
  font-family: "system-ui";
  font-size: 16px;
}
.kanban-footer {
  border-top: 1px solid #cfd4d1;
  height: 2vh;
  padding: 2px;
  text-align: right;
}
.kanban-footer-button {
  color: #f73620;
}
.sidemenu {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  //  direction: rtl;
}
.sidemenu::-webkit-scrollbar {
  width: 1em;
  height: 0.5em;
}
.sidemenu::-webkit-scrollbar-horizontal {
  /* Select the down or left scroll button when it's being hovered by the mouse */
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
.sidemenu::-webkit-scrollbar-button:single-button {
  width: 16px;
  height: 13px;
  background-color: #bbbbbb;
  display: block;
  border-style: solid;
}

.sidemenu::-webkit-scrollbar-button:single-button:horizontal:decrement {
  // border-width: 8px 8px 8px 8px;
  // border-color: transparent transparent #555555 transparent;
}

.sidemenu::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}
/* Down */
.sidemenu::-webkit-scrollbar-button:single-button:horizontal:increment {
  //  border-width: 8px 8px 8px 8px;
  // border-color: #555555 transparent transparent transparent;
}

.sidemenu::-webkit-scrollbar-button:horizontal:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}
.top-bar-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
}
.item {
  align-self: auto;
  margin-right: 80px;
}
.el-table .cell {
  word-break: break-word;
}
.el-table .td {
  word-wrap: break-word;
}
</style>
